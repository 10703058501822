<template>
  <div>
    <v-row>
        <v-col align="center">
        <!-- <v-text-field class="centered-input"
                v-model="search"
                append-icon="search"
                label="Search Module..."
                single-line
                hide-details
                style="width: 50%"
              ></v-text-field> -->
              <h3>FAQs</h3>
        </v-col>
      </v-row>
    <!-- <v-container fluid> -->
      <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height style="margin: 8px; height:90%;padding:15px;">
             <u><span style="font-size:30px;font-weight:bold;color:blue;"> Personal Details ?</span></u><br>
             <span>* Latest completed education will be the highest education.(Ex. :For UG students it is HSC/Diploma, For PG students it is Graduation)</span> 
            </v-card> 
          </v-col> 
      </v-row>
     
      <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;"> 1. How to fill personal Details ?
             </span><br>
             <span>* Go to fill Profile Option.</span>
            </v-card>
          </v-col>
      </v-row>

      <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;"> 2. What is the highest qualification?
             </span><br>
             <span>* HSC/Diploma for Under Graduate students and Graduation for Post Graduation student.</span>
            </v-card>
          </v-col>
      </v-row>
      <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;"> 3. If I apply for a particular company but later I did some changes to the profile. So do the changes get automatically updated for that particular company?
             </span><br>
             <span>* Yes </span>
            </v-card>
          </v-col>
      </v-row>
      <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">4. Which is to be marked as highest education ?
             </span><br>
             <span>* Latest completed education will be the highest education.(Ex. :For UG students it is HSC/Diploma, For PG students it is Graduation)</span>
            </v-card>
          </v-col>
      </v-row>
      <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">5. What is latest CGPA/CPI?
             </span><br>
             <span>* CGPA/CPI of last semester you completed</span>
            </v-card>
          </v-col>
      </v-row>
      <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">6. What is need to be filled in Examination details ? 
             </span><br>
             <span>* Details of all semester Marks you have completed(i.e.FY,SY,or TY,BTech)</span>
            </v-card>
          </v-col>
      </v-row>
            <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">7. Showing Wrong CPI ?
             </span><br>
             <span>Edit it and SAVE correct one.</span>
            </v-card>
          </v-col>
      </v-row>
            <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">8. What is need to be filled in Academic details?
             </span><br>
             <span>* Details of - SSC, HSC/Diploma, Graduation, PG.(In case of pursuing education mention CPI of last attended semester)</span>
            </v-card>
          </v-col>
      </v-row>
            <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">9. What does it mean by Is Degree Completed in academic details ? 
             </span><br>
             <span>* Completed education need to be marked as Is Degree complted yes.(For UG Student - HSC/Diploma should be marked as YES. For PG Student-Graduation should be marked as YES)</span>
            </v-card>
          </v-col>
        </v-row>
            <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">10. How do TPO Register for the company? 
             </span><br>
             <span>* Select TPO Registration click on the NEW TPO REGISTRATION Button select Academic year and Internship, Placement, Internship and Placement.</span>
            </v-card>
          </v-col>
      </v-row>
            <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">11. How do TPO Register for the company?
             </span><br>
             <span>* Select TPO Registration click on the NEW TPO REGISTRATION Button select Internship, Placement, Internship and Placement.</span>
            </v-card>
          </v-col>
      </v-row>
            <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">12. Unable to see the schedule companies. 
             </span><br>
             <span>* Check company criteria.</span>
            </v-card>
          </v-col>
      </v-row>
            <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">13. Not Able to log in?
             </span><br>
             <span>* Same as UserID and Password.For <strong>VIT:</strong>:yourprnnumber@vit.edu ,VIIT:yourprnnumber@viit.ac.in, VU: yourprnnumber @vupune.ac.in</span>
            </v-card>
          </v-col>
      </v-row>
            <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">14. Unable to see the schedule companies ?
             </span><br>
             <span>* Check company criteria.</span>
            </v-card>
          </v-col>
      </v-row>
            <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">15. How to Upload Resume?
             </span><br>
             <span>* Go to fill Profile Upload Resume.</span>
            </v-card>
          </v-col>
      </v-row>
            <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">16. Can I add a Multiple Domain Resume?
             </span><br>
             <span>* Yes you Can. While applying for the company you can refer to any of them.</span>
            </v-card>
          </v-col>
      </v-row>
            <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">17. Can I delete my Old Resume? 
             </span><br>
             <span>* You can delete it. But if the resume is referred to for Company then you can't delete it.</span>
            </v-card>
          </v-col>
      </v-row>
            <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">18. Showing wrong details in fill profile ?
             </span><br>
             <span>* Try to edit. if not contact to TPO office.</span>
            </v-card>
          </v-col>
      </v-row>
            <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">19. Scheduled company is not visible?
             </span><br>
             <span>* Enter your Academic, Examination, and Personal Details SAVE and FREEZE it.</span>
            </v-card>
          </v-col>
      </v-row>
            <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">20. If I mistakenly uploaded the wrong resume can I Update it?
             </span><br>
             <span>* Yes, you can by Using the Upload CV Option in your Login</span>
            </v-card>
          </v-col>
      </v-row>
            <v-row dense>
          <v-col cols="12" sm="12" >
            <v-card :elevation="5" id="card-hover" fill-height class="question">
             <span style="font-weight:bold;">21. showing criteria mismatch What should I do?
             </span><br>
             <span>* Go to Scheduled Company Option CLICK on the MORE option. Check Company Criteria.</span>
            </v-card>
          </v-col>
      </v-row>
      
    
  </div>
</template>

<script>
import axios from 'axios'
export default {
        
  data() {
    return {
      routerLinkList: [],
       search:'',
    };
  }, // end of data()
  mounted(){
  },
  computed: {
  } // end of computed()

};
</script>
<style scoped>
.card-title{
font-weight: 400;
color: #9E9E9E;
}
.add-plus-icon{
        color: #616161
}
.avatar-icon {
  border: 1px solid #0000001a;
}
.link-icon {
  color: #bdbdbd;
}

#card-hover {
  transition: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
#card-hover:hover {
  background: #ededed;
}
#card-hover:hover .link-icon {
  color: #3f51b5;
}
#card-hover:hover .avatar-icon {
  border-color: #3f51b5;
  background: white;
}
#card-hover:hover .card-title {
   color: #777777;
   
}
#card-hover:hover .add-plus-icon {
  
   border: none;
   border-radius: 100%; 
   color: white;
   background:#3f51b5;
}
.link-icon {
        color: rgb(19, 74, 192);
    }
    .btn-title{
        text-transform: uppercase;
      background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
}
.question{
    margin:8px; height:100%;padding-top:15px;padding-left:15px;padding-right:15px;
}

</style>